import React from "react";
import { growthStrategist } from "../../../constants";
import { benefits } from "../../../constants";
const Details = () => {
  return (
    <div className="container" data-aos="fade-up" data-aos-duration={600}>
      <div className="">
        {growthStrategist.map((items, index) => (
          <>
            <h2 key={index} className="font-size-9 mb-4 pb-3">
              {items.title}
            </h2>
            {items.items.map((item, index) => (
              <>
                <h3 className="font-size-7 mb-1 pb-3" key={index}>
                  {item.subtitle}
                </h3>
                <ul>
                  {item.para.map((points, index) => (
                    <li
                      className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5"
                      key={index}
                    >
                      {points}
                    </li>
                  ))}
                </ul>
              </>
            ))}
          </>
        ))}

        <h2 className="font-size-9 mb-4 p-3">Benefits:</h2>
        <ul>
          {benefits.map((benefit, index) => (
            <li
              className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5"
              key={index}
            >
              {benefit}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Details;
