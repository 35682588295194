import React from "react";

const AboutCareer = () => {
  return (
    <div data-aos="fade-in" data-aos-duration={900}>
      <div data-aos="fade-up" data-aos-duration={600}>
        <div className="row justify-content-center">
          <div className="text-xl-justify pr-lg-10 pr-xl-0 col-lg-12 col-sm-12 col-md-12 col-xs-12">
            <h3 className="font-size-8 mb-0 mt-10">
              Cloudstack, a dynamic and innovative technology company, is
              seeking a Growth Strategist and Innovation Architect to join our
              high-energy team. We are on a mission to revolutionise marketing
              and sales, and we need a visionary thinker to drive our growth
              strategies and shape the future of our products.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCareer;
